import CircleLoader from '@components/loader/CircleLoader';
import GearLoader from '@components/loader/GearLoader';

export default function Loader(props) {
  const {
    type = 'circle',
  } = props;

  switch (type) {
  case 'circle':
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <CircleLoader {...props} />
      </div>
    );
  case 'gear':
    return (
      <GearLoader />
    );
  default:
    return null;
  }
}
