import { motion } from 'framer-motion';

const GEAR_IMAGE_URL = 'https://kinn-app.s3.amazonaws.com/kinndoms/kings-events/kings-gear-loader-gold.png';

export default function GearLoader(props) {
  const { opacity = 1 } = props;

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center overflow-hidden">
      <motion.div
        className="relative"
        initial={{
          opacity: 0,
          scale: 0,
          // filter: 'blur(1rem)',
          transition: {
            duration: 0.2,
            ease: 'easeOut',
          },
        }}
        animate={{
          opacity: opacity,
          scale: 1,
          // filter: 'blur(0)',
          transition: {
            duration: 0.3,
            ease: 'easeOut',
          },
        }}
        exit={{
          opacity: 0,
          scale: 1,
          // filter: 'blur(1rem)',
          transition: {
            duration: 0.1,
          },
        }}
      >
        <img
          className="size-[200px] animate-spinSlow md:size-[200px]"
          src={GEAR_IMAGE_URL}
          alt="Gear Loader"
        />
      </motion.div>
    </div>
  );
}
