/***************************************************************************************************
** WebsiteContext
***************************************************************************************************/

import { use, createContext, useState, useEffect } from 'react';

import useKinnFetch from '@hooks/useKinnFetch';

/**************************************************************************************************/

export const WebsiteContext = createContext();

export const WebsiteContextProvider = (props) => {
  const [website, setWebsite] = useState({
    id: window.appContext.websiteId,
    parameters: {},
  });

  const { data, status } = useKinnFetch(['websites', window.appContext.websiteId]);

  useEffect(() => {
    if (status === 'fetched') {
      setWebsite({
        ...data,
        parameters: data.parameters || {},
      });
    }
  }, [status, data]);

  return (
    <WebsiteContext value={website}>
      {props.children}
    </WebsiteContext>
  );
};

/**************************************************************************************************/

export const useWebsiteContext = () => {
  const context = use(WebsiteContext);

  if (!context) {
    throw new Error('WebsiteContext must be used within a WebsiteContextProvider');
  }

  return context;
};
