import Loader from '@components/loader/Loader';
import MessageCard from '@components/elements/MessageCard';

export default function PageContent(props) {
  const {
    isLoading,
    displayContent,
    noContentTitle = 'No information found',
    noContentMessage = 'Sorry, we encountered an issue loading your data.',
  } = props;

  if (isLoading) return <Loader />;

  if (!displayContent) {
    return (
      <MessageCard title={noContentTitle}>
        {noContentMessage}
      </MessageCard>
    );
  } else {
    return (
      <>
        {props.children}
      </>
    );
  }
}
