import cn from '@utils/helpers/cn';
import Card from '@elements/Card';

export default function MessageCard(props) {
  const {
    className,
    title,
    titleClassName,
    content,
    children,
  } = props;

  const classNames = cn([
    'text-center',
    className,
  ]);

  const titleClassNames = cn([
    'mb-3',
    'font-serif',
    'text-3xl',
    titleClassName,
  ]);

  if (!content && !children) return null;

  return (
    <Card className={classNames}>
      {title && (
        <h2 className={titleClassNames}>
          {title}
        </h2>
      )}

      {title && (
        <hr className="my-3 border-card-default-hr" />
      )}

      <div className="text-base md:text-lg">
        {content || children}
      </div>
    </Card>
  );
}
