import { lazy, Suspense, StrictMode } from 'react';
import ReactDOMClient from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { cssTransition, ToastContainer } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import { WebsiteContextProvider } from '@contexts/WebsiteContext';
import ErrorPage from '@pages/ErrorPage';
import deepFreezeObject from '@utils/helpers/object/deepFreezeObject';
import getAuthenticityToken from '@utils/helpers/document/getAuthenticityToken';

const loadPageComponent = (componentName) => {
  return lazy(() => {
    return import(`./../javascripts/${componentName}`).catch((error) => {
      console.error(error);
      return import('./../javascripts/App');
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const urlParams = new URLSearchParams(window.location.search);

  window.appContext = deepFreezeObject({
    ...document.body.dataset,
    isAuthenticated: document.body.dataset.isAuthenticated === 'true',
    isImpersonation: urlParams.has('spoof'),
    isKings: document.body.dataset.kinndomKey === 'kings-events',
    authenticityToken: getAuthenticityToken(),
  });

  console.info(window.KINN_MARK);

  /**************************************************************************************************/

  const appElement = document.getElementById('app');
  if (appElement === null) throw new Error('Missing app element');

  const app = ReactDOMClient.createRoot(appElement);
  const componentName = appElement.dataset.component;
  if (componentName === null) throw new Error('Missing component');

  const Component = loadPageComponent(componentName);
  const props = JSON.parse(appElement.dataset.props);

  app.render(
    <StrictMode>
      <Suspense>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <WebsiteContextProvider>
            <Component {...props} />

            <Tooltip
              id="app-tooltip"
              className="tooltip"
              place="bottom"
              delayHide={200}
              opacity={1}
            />
            <ToastContainer
              theme="dark"
              position="top-right"
              autoClose={3500}
              newestOnTop={false}
              closeOnClick={true}
              rtl={false}
              pauseOnFocusLoss={true}
              draggable={true}
              draggablePercent={40}
              transition={cssTransition({
                enter: 'animate__animated animate__bounceInDown',
                exit: 'animate__animated animate__fadeOut animate__faster',
              })}
            />
            <div id="modal-root" />
          </WebsiteContextProvider>
        </ErrorBoundary>
      </Suspense>
    </StrictMode>
  );
});
