import logError from '@utils/logError';
import cn from '@utils/helpers/cn';
import { WebsiteContextProvider } from '@contexts/WebsiteContext';
import Card from '@elements/Card';
import MotionDiv from '@components/elements/MotionDiv';

import Page from '@components/page/Page';

const classNames = cn([
  'col-center-span-12',
  'md:col-center-span-10',
  'lg:col-center-span-6',
]);

const CONFIG = {
  title: 'Error',
  displayPageHeader: true,
};

export default function ErrorPage(props) {
  const {
    error,
    _resetErrorBoundary,
  } = props;

  if (error) {
    console.error(error.message);
    logError(error);
  }

  /**************************************************************************************************/

  return (
    <WebsiteContextProvider>
      <Page
        className={classNames}
        config={CONFIG}
      >
        <MotionDiv key="error" type="card">
          <Card
            className="text-center"
            content="Something went wrong!"
          />
        </MotionDiv>
      </Page>
    </WebsiteContextProvider>
  );
}
